<template>
	<div>
		<b-modal v-model="removeuser" id ="approvalchanges" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Confirm</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="removerole = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> This action will 
	        remove you as an owner and you will immediately lose access to this settings page.
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes = "tryremoveuser( activeuser, 'confirm',true)"></button-gruppe>
	        </template>
        </b-modal> 

      	<b-modal v-model="removerole" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
      		<template v-slot:modal-header>
	          <h3 class="modal-title">Confirm</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="removeuser = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> This action will 
	        remove you as an owner and you will immediately lose access to this settings page. If you proceed and later on regret removing yourself, then you will need to ask another owner to add you as owner again.
	        
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes = "switchrole( activeuser, torole, true )"></button-gruppe>
	        </template>
      	</b-modal> 


      	<b-modal v-model="addnoaccesstolist" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Restore access and add?</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addnoaccesstolist = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> You are adding a user that has previously had their access to the organization revoked, Adding them will restore their access to the organization as well as any projects and objectives they previously had access to. Do you wish to proceed?
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes = "userselectwarningproceed()"></button-gruppe>
	        </template>
        </b-modal>      	
      	
	 	<memberslist 
	 	@onexpandme="expandme"
	 	@accessrequest ="accessrequest" 
	 	@tryremoveuser ="tryremoveuser" 
	 	@switchrole="switchrole"
	 	@resendemail="resendemail"
	 	:adminrole="adminrole" 
	 	:roles = "roles" 
	 	:users = "combinedusers" 
	 	:myrole ="myrole" 
	 	:isactive ="isactive" 
	 	:numowners="numowners" 
	 	:username="username" 
	 	:hasremove ="hasremove"
	 	:isworking="isworking"
	 	:isadmanaged="isadmanaged"
	 	:includeteams="includeteams"
	 	:listtype="listtype"/>


	 	<membersearch 
	 	@onupdated="onupdated" 
	 	@availableusersupdate="availableusersupdate" 
	 	@userSelect ="userSelect" 
	 	@oninviteuser ="inviteuser" 
	 	:searchtext="searchtext"
	 	:adminrole="adminrole" 
	 	:users="combinedusers" 
	 	:availableusers="availableusers" 
	 	:myrole ="myrole" 
	 	:isactive ="isactive" 
	 	:username="username" 
	 	:allowadduser="allowadduser" 
	 	:alreadyonlist ="alreadyonlist"
	 	:sendaninvite="sendaninvite"
	 	:hasnodrop ="hasnodrop"
	 	:searchquery ="searchquery"
	 	:isworking="isworking"
	 	:isadmanaged="isadmanaged"
	 	:cockpitupgradestatus="cockpitupgradestatus"
	 	:membersmodaltype="membersmodaltype"
	 	@onsearchactive="searchactive"
	 	/>

	</div>
</template>

<style lang =" scss" ></style>

<script type="text/javascript"></script>

<script>
	import memberslist from '@/components/members/memberslist' 
	import membersearch from '@/components/members/membersearch' 
	import Userapi from '@/services/api/users'
 	import Cockpitsapi from '@/services/api/cockpits'
 	import Emailsapi from '@/services/api/emails'
 	import Teamsapi from '@/services/api/teams'
 	
 	

	import {bus} from '@/main'

	export default{
		name:'memberlistfull',
		props:{	
			users:{
		        type: Array,
		        default: () => []
		      },
			teamusers:{
		        type: Array,
		        default: () => []
		      },
			includeteams:{
				type: Boolean,
				default: false
			},
			isadmanaged:{
				type: Boolean,
				default: false
			},
			hasonindicator:{
				type: Boolean,
				default: false
			},
			hasnodrop:{
				type: Boolean,
				default: false
			},
			searchtext:{
				type: String,
				default:'Search by name or email, or enter an email address to invite a new user to join.'
			},			
			membersmodaltype:{
	            type:String,
	            default: ''
	        },
			cockpitupgradestatus:{
		        type:String,
		        default: ''
		    },
			orgcontext:{
		        type:String,
		        default: ''
		    },
		    hasremove:{
		    	type:Boolean,
		    	default: true
		    },
			myrole:{
		        type:String,
		        default: 'Viewer'
		    },
		    allowadduser:{
		    	type: Boolean,
		    	default: true
		    },
		    roles:{
		        type: Array,
		        default: () => []
		    },
		    isactive:{
		        type: Boolean,
		        default: false
		    },
		    listtype:{
		        type: String,
		        default: 'inboards'
		    },
		    adminrole:{
		        type: String,
		        default: 'Owner'
		    },
		    defaultrole:{
		    	type: String,
		    	default: 'Viewer'
		    },

		    isworking:{
		    	type:Boolean,
		    	default: false
		    },
		    
		    Hasbeenapprovedemailtouser:{
		    	type: Function,
		    	default: () => {}
		    },
		    Hasbeenapprovedemailtoowner:{
		    	type: Function,
		    	default: () => {}
		    },
		    Hasbeenrejectedemailtouser:{
		    	type: Function,
		    	default: () => {}
		    },
		    Hasbeenrejectedemailtoowner:{
		    	type: Function,
		    	default: () => {}
		    },
		    apiGetUsers:{
		    	type: Function,
		    	default:(search, userids, orgcontext, includeteams) =>{
		    		return Cockpitsapi.Getusers( search, userids, orgcontext, includeteams)
		    	}
		    },
		    apiRegisterUser:{
		    	type: Function,
		    	default:(email, token, orgcontext)=>{
		    		return  Userapi.Registeruser( email, token, orgcontext )
		    	}
		    },
		    apiInvitationEmail:{
		    	type: Function,
		    	default:(user, email, context)=>{
		    		Emailsapi.Invitationemail( user, email, context)
		    	}
		    },
		    apiInvitationorgEmail:{
		    	type: Function,
		    	default:(user, email, context)=>{
		    		Emailsapi.Invitationorgemail( user, email, context)
		    	}
		    },

		    cockpit:{
		    	type: Object,
		    	default: () => {}
		    },
		    sortmethod:{
		    	type:Array,
		    	default: () => [ "OWNER","ADMINISTRATOR","MEMBER","CONTRIBUTOR","VIEWER", "INVITED","DISABLE"]
		    }
		},
		data(){
			return{
				//users:[],
				sharedtoteams:[],
				activeuser:{},
				removeuser:false,
				torole: '',
				removerole: false,
				switchroleself: false,
				availableusers: [],
				sendaninvite :false,
				sendaninvitetouser:"",
				userexists: {},
				searchquery: '',
				alreadyonlist:false,
				addnoaccesstolist:false,
				activeoptionselected:null

			}
		},
		mounted(){

			bus.$off('initmemberslist' )
			bus.$on('initmemberslist', this.initcomponent )

		},
		watch: {

			//isworking : function( e, ev ){

				//if( e == false ){
					//alert('')
					
				//	for( var i in this.users ){
				//		console.log( this.users[i].status, "pppp" )
				//	}

				//	this.$emit('onupdateuserslist', this.users)
				//}
				//console.log( e, ev, "isworking")
			//}

		},
		computed: {

			combinedusers(){


				return this.users
				/*
				if( this.includeteams && this.sharedtoteams ){

					return this.users.concat( this.sharedtoteams )
			    				
				}else{

					return this.users
				}*/

				
			},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

			exemptids(){

				var ids = []
				for( var i in this.combinedusers ){
					ids.push( this.combinedusers[i]._id )
				}
				return ids
			},

			token(){
		      return localStorage.token
		    },


			 myroleupper(){
		        return this.myrole.charAt(0).toUpperCase() + this.myrole.slice(1)
		      },

		      sortusers(){
		      	var sortusers = this.users

		      	return sortusers
		      },

		      userids(){

		      	return this.exemptids
		      },

			numowners(){
				var numowners = 0


				for(var i in this.users){					
				
					//let isteam = false

					/*if( this.users[i].usertype ){

						isteam = this.users[i].usertype == 'Team' ? true : false


						if( this.users[i].usertype == 'Team' && this.users[i].type == 'everyone' && (this.users[i].role.toUpperCase() == this.adminrole.toUpperCase()) ){
							numowners ++;
						}

						if( isteam ){

							if( this.users[i].users && this.users[i].users.length > 0 ){

								let users = this.users[i].users

								for(var o in users){
									console.log( users[o].firstname )
								}
							}

						}

					}*/
					// && !isteam



					if( (this.users[i].role.toUpperCase() == this.adminrole.toUpperCase())  ){
						numowners ++;
					}
				}

				return numowners;
			},

				loggedinuser(){
					return this.$store.getters.user
				}
			},
		methods: {

			expandme( item , el ){

				item.expanded = item.expanded == true ? false : true
//table-objusers
//el.target.parentNode.parentNode.parentNode.parentNode.parentNode
				this.ajustlengthdown(  )

			},
			searchactive( val ){

				this.$emit('onsearchactive', val)

			},
			initcomponent(users, userids, teamusers){

				/*
				if( teamusers && teamusers != undefined ){
					this.sharedtoteams = teamusers
				}
				
				this.users = users
				*/


				this.updatusers()
				this.applysort()
			},

			onupdated(field, value){
				this.sendaninvite = false
				this.updatusers()
				this.$emit('onupdated', field, value )
				this.$emit('onupdateuserslist', this.users)
			},

			applysort(){

				//alert( this.adminrole.toUpperCase() )

				var sortmethod = this.sortmethod
				var sorted = []

				var unsorted = []

				//console.log( sortmethod )
			

				for (var i in sortmethod){

					var sortm = sortmethod[i]


					for (var i in this.users){

						var row = this.users[i]

						if( row.role.toUpperCase() == sortm ){

							if( row.status.toUpperCase()=='INVITED' ){
								unsorted.push(row)
							}else{
								sorted.push( row )								
							}
							
						
						}else{							

							if( !sortmethod.includes( row.status.toUpperCase() ) ){
 
							}
						}

					}

				}

				/*var roles = []

				var newusers = []

				for (var i in this.users){
				
					var row = this.users[i]
					var role = row.role

					sorted.push( row )

					var cntr = 0

					if( !roles.includes(role) ){
						roles.push(role)


						newusers[cntr] = []

						cntr++

					}

				}*/



				this.$emit('onupdateuservar', sorted.concat(unsorted))
				//this.users = sorted.concat(unsorted)
	

			},

			updatusers(){


				var ids = []

				let users = this.users

				for (var i in users){

					var user = users[i]._id ? users[i] : users[i].user

					if( user ) {

						ids.push( user._id )


						var role = users[i].role

						user.role = role

		
						for(var n in this.roles){

							if ( role == this.roles[n].value || role == this.roles[n].label ){
								user.rolekey = n;
							}
						}
						if(user.status.toUpperCase() == "PENDINGJOIN"){
							user.rolekey = this.roles.length +1
						}

						if( user.status.toUpperCase() == 'INVITED'){
							user.rolekey = this.roles.length + 2
						}
				

						var fullname = []

						if( user.firstname ){
							fullname.push( user.firstname)
						}
						if( user.lastname ){
							fullname.push( user.lastname)
						}
						if( user.email ){
							fullname.push( user.email)
						}

						user.searchword = fullname.join(" ")

						users[i] = user


					}
					
				}

				this.$emit('onupdateuserslist', users)
				


				//console.log( this.users, "xxx")

				

			    this.ajustlengthup()
			},

			// sortrim(arr, key){

			// 	return arr.sort(function (a, b){
					
			// 		if( a[key] && b[key] && typeof a[key].toLowerCase === 'function' && typeof b[key].toLowerCase === 'function' && a[key].toLowerCase() && b[key].toLowerCase()){	
			//           if(a[key].toLowerCase() < b[key].toLowerCase()) { return -1; }
			//           if(a[key].toLowerCase() > b[key].toLowerCase()) { return 1; }
			//           if(a[key].toLowerCase() == b[key].toLowerCase()){
			//           	return 0
			//           }
			//         } 
			//     });
		 //    },
	
			username(user){
				let username = ''
	          	username += user.firstname ? user.firstname:'';
	          	username += user.lastname ? ` ${user.lastname}`:'';
	          	username += !username && user.email ? user.email:'';

	          	return username
			},

			switchrole( user, role, confirm = false){

				let users = this.users

				if( user.usertype && user.usertype == "Team" ){

					user.role = role
		        	
		        	this.$emit('onupdated', "teams", sharedtousers, {"updated":user._id, "action":"changerole", "newrole":role } )
		       		this.$emit('updateuserslist', users)
		        

		        }else{		        

				var isorgpanel = false

				if( this.adminrole.toUpperCase() == "ADMINISTRATOR" ){
					isorgpanel = true
				}

		        var sharedtousers = []
		         let self = this
		        if( this.loggedinuser._id == user._id && role != this.adminrole && !confirm ){

		          this.removerole = true
		          this.activeuser = user
		          this.switchroleself = true
		          this.torole = role

		          return false

		        }

		        this.activeuser = {}
		        this.torole = ""

		        user.role = role

		        let changed = {}

		        for( var i in users ){

		          var newrole = users[i].role

		          var status = users[i].status

		           //console.log( newrole, "ppppp")

		          if( isorgpanel ){
		          	status = users[i].orgstatus
		          }


		          if( user._id == users[i]._id ){

		          	///////////////////xxxxxxxxxxxxxxxxxxxx
		          	this.$set(users, i, user)
		            newrole = role

		            if( isorgpanel ){
		          	 user.orgrole = role
		            }


		            if( newrole.toUpperCase() != "DISABLE" ){

		            }


		            if( isorgpanel && newrole.toUpperCase() == "ADMINISTRATOR" ){
		          		status = "active"
		          		users[i].orgstatus = status
		          	}

		          	//this.users[i].status = status


		          	changed = user
		            
		          }

		          sharedtousers.push( { "id": users[i]._id , "role":newrole, "status": status } )
		        }

		       

		        this.removerole = false
		        if(this.switchroleself){
		        	 setTimeout( function(){        
			          	self.$store.commit('UPDATE_FORCEREFRESH')
			          }, 500)
		        }
		        this.updatusers()

		        

		        this.$emit('onupdated', "users", sharedtousers, {"updated":user._id, "action":"changerole", "newrole":role } )
		        this.$emit('updateuserslist', users)

		        /////////////Teamsapi.Updateuserstatus( user._id, self.orgkey )

		        }

		       
		    },

		    resendemail( user ){
		    	

		    	this.inviteuser(true, user)
		    	this.searchtext = ""
		    },

		    inviteuser(isresend , user ){

		    	//alert( isresend )		    	

			   	 let self = this

		         var email = this.sendaninvitetouser

		         var str1 = 'Invite sent'

		         //alert("pppp")

		         if( isresend ){

		              var str = ""

		              self.apiInvitationEmail( self.loggedinuser._id, user.email, self.orgkey)

		              /*
		              We have re-sent the invitation email to the address you indicated. Once they sign up, they will automatically have the accesses you have indicated in the members list.*/
		              str = "We have sent an invitation to the email address you indicated."
					  

		              setTimeout( function(){
		                $('.vs__search')[0].blur()
		              }, 100)

		               		               
		              bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})

		         }else{		         

			         this.apiRegisterUser( email, this.token, this.orgcontext, isresend )
			          .then( function(response){

			            
			            if( response.data.ok ){

			            	if( response.data.user && response.data.user._id != null ){


				              self.userSelect( response.data.user , true, true )

				              var str = ""

				              self.apiInvitationEmail( self.loggedinuser._id, email, self.orgkey)

				              str = "We have sent an invitation to the email address you indicated."
							  

				              setTimeout( function(){
				                $('.vs__search')[0].blur()
				              }, 100)			               
				               
				              bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})


			            	}else{

								var str = "Failed to send an invitation email to the address you indicated."
			               		bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})


			            	}


			                

			            }else{

			              var str = "Failed to send an invitation email to the address you indicated."

			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})

			            }
			        }).catch( function( err ){

			        	console.log("rollback")

			        })

		      	}

		         
		    },

		    userselectwarningproceed(){

		    	this.addnoaccesstolist = false
		    	this.userSelect( this.activeoptionselected , true )

		    },

		    userSelect( selected, force, isinvite ){

		    	//this.activeoptionselected = selected

		    	//if( selected.orgrole.toUpperCase() == "DISABLE" && !force  ){

		    		//this.activeoptionselected = selected
		    	//	this.addnoaccesstolist = true
		    	
		    	//}else{

		    	this.activeoptionselected = null
		    	selected.orgrole = "Member"


		    	let self = this

		        var sharedtousers = []

		        if( selected && selected.resulttype == 'team' ){

		        	if( ( this.cockpitupgradestatus == "limited" ||   this.cockpitupgradestatus == "request" ) && this.membersmodaltype == 'cockpit' ){

		        	}else{
		        		this.$emit('onupdated', "teams", sharedtousers, {"added":selected._id, "action":"addteam", "selected":selected } )
		        		this.sharedtoteams.push( selected )
		        	}


		        }else{

		        	let users = this.users

		          var newavailableusers = []
		          var existingids = []

		          for(var i in users ){
		            existingids.push( users[i]._id )
		          }

		          if( existingids.indexOf(selected._id) == -1 ){

		          for(var i in users ){

		            sharedtousers.push( { "id": users[i]._id , "role":users[i].role, "status":users[i].status } )
		          }

		          for(var i in this.availableusers ){


		            if( this.availableusers[i]._id != selected._id ){

		              newavailableusers.push( this.availableusers[i])              

		            }else{


		              var torole = this.defaultrole

		              if( selected.orgrole && selected.orgrole == "Disable" ){
		              	selected.orgrole = "Member"
		              }
		             
					selected.role = torole
		            this.availableusers[i].role = torole


		            this.$set(this.users, users.length, selected)
		            this.$emit('updateuserslist', users)

		            sharedtousers.push( { "id": this.availableusers[i]._id, "role": torole, "orgrole" : ( selected.orgrole ? selected.orgrole : "" ), "orgstatus" : ( selected.orgstatus ? selected.orgstatus : "" ) } )
		            }

		          }

		          if( force == true ){

		            selected.role = this.defaultrole
		            selected.mode = 'idle'

		            //if( isinvite ){
		            //	selected.status = 'invited'
		            //}
		
		          	this.$set(this.users, users.length, selected)
		          	this.$emit('updateuserslist', users)

		            sharedtousers.push( { "id": selected._id , "role":selected.role } )
		            
		            this.sendaninvitetouser = ""
		          }

		          this.availableusers = []
		          this.availableusers = newavailableusers

		          /////Teamsapi.Updateteamuserstatus( selected._id, this.orgkey )


		          this.$emit('onupdated', "users", sharedtousers, {"added":selected._id, "action":"adduser", "selected":selected } )

		          this.updatusers()


		          }

		        }


		    	//}


		    },
		    availableusersupdate( search, loading ){

		        let self = this

		        this.sendaninvite = false
		        this.alreadyonlist = false

		        if( this.searchbounce ){
		          clearTimeout(this.searchbounce)
		        }

		        this.searchbounce = setTimeout( function(){

		          if( search ){
		            self.searchquery = search
		          }        

		          if( search && search.length > 1 ){

		            loading(true)

					self.apiGetUsers( search, self.exemptids, self.orgcontext, self.includeteams )
		            .then( function(response){      

		              if( response.data ){

		                self.sendaninvite = response.data.sendaninvite
		                self.alreadyonlist = response.data.alreadyonlist

		                if( self.sendaninvite == true ){
		                  self.sendaninvitetouser = response.data.inviteuser
		                } 
		                self.userexists =  response.data.userexists          

		                self.availableusers = response.data.users
		               	//console.log('HELLO', self.availableusers.some(item => item._id === self.userexists._id))
		                self.availableusers.find(item => item._id === self.userexists._id).userexist = true
		                //console.log('HELLO',self.availableusers )
		                       
		            }

		          })
		            .catch( function(){

		              //self.cockpitsettingsmodalshown = false
		            })
		            .finally( () => {
		              loading(false)
		               self.ajustlengthdown()
		           })

		          }else{

		            self.availableusers = []
		            self.ajustlengthdown()
		            self.searchquery = ""

		          }

		        }, 500)
		       

		    },

			accessrequest : function( user, isapproved , ev ){

		        var newroles = []
		        var newusers = []
		        var newuserids = []

		        var userids = ""

		        var ownerids = []

		        let self = this

		        let users = this.users

		        $.each( users, function(i,o){

		          if( o._id == user._id ){

		            if( isapproved ){

		              if( o.status == 'pendingjoin' ){
		                newusers.push(o)
		                newroles.push( { "id":o._id, "role":o.role} )

		                userids = o._id
		              }                   

		              newuserids.push(o._id)  
		            }

		          }else{

		          	//alert( this.adminrole )

		            if( o.role.toLowerCase() == self.adminrole.toLowerCase() && o._id != self.loggedinuser._id ){
		              ownerids.push( o._id )
		            }

		            newusers.push(o)
		            newroles.push( { "id":o._id, "role":o.role, "status":o.status } )
		            newuserids.push(o._id) 

		          }
		        })

        	//this.userids = newuserids
        	users = newusers
        	this.$emit('onupdateuserslist', users)

      		//approve or denied      		

			if( isapproved ){


			this.Hasbeenapprovedemailtouser( user._id, this.cockpit._id, this.loggedinuser._id)

				for (var i in ownerids){
					this.Hasbeenapprovedemailtoowner( user._id , this.cockpit._id , ownerids[i], this.loggedinuser._id)
				}

			}else{

			this.Hasbeenrejectedemailtouser( user._id, this.cockpit._id, this.loggedinuser._id)              

				for (var i in ownerids){
					this.Hasbeenrejectedemailtoowner( user._id , this.cockpit._id, ownerids[i],  this.loggedinuser._id) 
				}

			}

			if( isapproved ){
				this.$emit('onupdated', "users", newroles, { "approved":user._id, "action":"approveuser" } )
			}else{
				this.$emit('onupdated', "users", newroles, { "rejected":user._id, "action":"rejectuser" } )
			}        	

			user.status = "active"

			this.$nextTick(function(){
				self.ajustlengthup()
				//sorting through the user Array and ordering it alphabetic after user name
				self.updatusers()
				self.showapproval = false
			})

	      	  
        	},

        	tryremoveuser( user, action, removeself, isteam ){

        		if( isteam ){

        			var newsharedtoteams = []

					for(var n in this.sharedtoteams ){

						if( this.sharedtoteams[n]._id != user._id ){
							newsharedtoteams.push( this.sharedtoteams[n] )
						}
					}

					this.sharedtoteams = newsharedtoteams

					//Teamsapi.Updateteamuserstatus( user._id, this.orgkey )

					

					this.$emit('onupdated', "teams", {}, { "remove":user._id, "action":"removeteam" } )


        			//this.updatusers()
			        //this.$emit('onupdateuserslist', this.users)


        		}else{

			       var sharedtousers = []

			       let self = this
			       // console.log(this.loggedinuser._id == user._id, this.loggedinuser._id, user._id)
			       if(this.loggedinuser._id == user._id  && !removeself){
			        this.removeuser = true
			        this.activeuser = user

			        //alert('a')
			      }else{
			        //console.log( action, user )

			        if(this.activeuser){
			          this.activeuser.mode='idle'
			        } 

			        if( action=='tryremove'){
			          this.approvalneed = 'remove'; 
			          this.showapproval = true 
			          this.activeuser = user
			          user.mode = "deleting"
			        }

			        if( action=='cancel'){
			          this.activeuser = user
			          this.showapproval = false
			          user.mode = "idle"
			        }

			        if( action=='confirm'){

			          this.showapproval = false
			          var newusers = []
			          var newuserids = []


			          for( var i in this.users ){

			           if( this.users[i]._id != user._id ){
			            //newuserids.push( this.users[i]._id )
			            newusers.push( this.users[i] )
			            sharedtousers.push( { "id": this.users[i]._id , "role":this.users[i].role, "status":this.users[i].status } )
			          }

			        }

			        //this.$emit('onupdateuserslist', newusers)
			        //this.users = newusers
			       // this.userids = newuserids
			        //this.cockpitupdate( "users", sharedtousers, function(){
			        //  self.loadusers()
			        //})

			        //console.log( newusers , "cc")

			        //this.$emit('onupdateuserslist', newusers)

			        this.$emit('onupdated', "users", sharedtousers, { "remove":user._id, "action":"removeuser" })
			        


			       //////// Teamsapi.Updateuserstatus( user._id, self.orgkey )


			        if( removeself == true ){

			          setTimeout( function(){        
			          	self.$store.commit('UPDATE_FORCEREFRESH')
			          }, 1000)

			        }

			        this.removeuser = false

			      }
			    }

				}

			},

        	ajustlengthdown( el ){

/*
		      this.$nextTick(function(){

		      	var box = $('#table-objusers').height()

		        var height = $('.vs__dropdown-menu').height()
		        var modalheight = $('.modal-body .innerbody').height()

		        var newheight = box + 170
		        $('#membersmodal___BV_modal_body_').height( newheight + 'px')
		       // console.log( box, height , modalheight)

		      })*/
		    },
		    ajustlengthup(){
		    // $('#membersmodal___BV_modal_body_').height('auto')
		   }
		},
		components: {
			memberslist,
			membersearch,
		}
	}
</script>